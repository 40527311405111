export default [
    {
        Id: 1,
        imgsrc: "http://www.asianplayschool.com/static/media/image0.9c0a4f48.jpeg",
        spinner:"text-primary",
    },
    {
        Id: 2,
        imgsrc: "http://www.asianplayschool.com/static/media/image2.9531a700.jpeg",
        spinner:"text-secondary",
    },
    {
        Id: 3,
        imgsrc: "http://www.asianplayschool.com/static/media/image12.d1d46d92.jpeg",
        spinner:"text-success",
    },
    {
        Id: 4,
        imgsrc: "http://www.asianplayschool.com/static/media/image3.44a491d5.jpeg",
        spinner:"text-danger",
    },
    {
        Id: 5,
        imgsrc: "http://www.asianplayschool.com/static/media/image8.f093fc5d.jpeg",
        spinner:"text-warning",
    },
    {
        Id: 6,
        imgsrc: "http://www.asianplayschool.com/static/media/image9.fc2310d6.jpeg",
        spinner:"text-info",
    },
    {
        Id: 7,
        imgsrc: "http://www.asianplayschool.com/static/media/image10.2bf33270.jpeg",
        spinner:"text-light",
    },
    {
        Id: 8,
        imgsrc: "http://www.asianplayschool.com/static/media/image11.6e6f9dd0.jpeg",
        spinner:"text-dark",
    },
    {
        Id: 9,
        imgsrc: "http://www.asianplayschool.com/static/media/image12.d1d46d92.jpeg",
        spinner:"text-warning",
    },
    {
        Id: 10,
        imgsrc: "http://www.asianplayschool.com/static/media/image1.452dcaba.jpeg",
        spinner:"text-success",
    },
    {
        Id: 11,
        imgsrc: "http://www.asianplayschool.com/static/media/image14%20(1).c0d69c3c.jpeg",
        spinner:"text-info",
    },

];
