import React, { useState, useEffect } from 'react'
import "./contact.css";
import axios from 'axios';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

ReactGA.initialize('UA-213150269-1');

const Contact = (props) => {
    const history = useHistory();
    const [firstname, setFirstName] = useState();
    const [lastname, setLastName] = useState();
    const [phone, setphone] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();


    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);


    }, [])



    const handleSubmit = async (e) => {
        e.preventDefault();
        axios.get(`http://api.digisidekick.com/api/Mail?Email=rajatgupta02607@gmail.com&Firstname=${firstname}&Lastname=${lastname}&Number=${phone}&Usermial=${email}&UserMessage=${message}&other=null`,)
            .then((res) => {
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");
                console.log(res.data)

            }).catch((error) => {
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");
                console.log(error)
            });
        setFirstName("");
        setLastName("");
        setphone('');
        setEmail("")
        setMessage("");
        history.push("/thank")



    }





    // function ValidateEmail(email) {
    //     if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) { return true; }
    //     return false;
    // }
    // const formValidation =  async (e) => {
    //     e.preventDefault();
    //     setErrorMessages([]);



    //     const isFirstNameValid = (firstname !== "");
    //     const isLastNameValid = (lastname !== "");
    //     const isMessageValid = (message !== "");
    //     if (!isFirstNameValid) {
    //         errors.push("First name is required !");
    //     }
    //     if (!isLastNameValid) {
    //         errors.push("last name is required !");
    //     }
    //     // if (!ValidateEmail(email)) {
    //     //     errors.push("Email is not valid, please try again !");
    //     // }
    //     if (email === "") {
    //         errors.push("Email is required !");
    //     }
    //     if (!isMessageValid) {
    //         errors.push("Message is required !");
    //     }

    //     if (errors.length > 0) {
    //         setShowErrors({ showErrors: true });
    //         setErrorMessages(errors);
    //     }
    //     else {
    //         setShowErrors({ showErrors: false });

    //         alert(" Sent");
    //     }
    // };
    return (
        <div >
            <Helmet>
                <title>{props.title}</title>
                <meta name="description" content="Asian Play School is a premium preschool that runs under the guidance of education professionals with a combined experience of over 30 years. Our focus is on fostering learning experiences for students." />
                <meta name="keywords" content="Asian Play  School, Pre School, Contact"></meta>
            </Helmet>

            <div className="row align-items-center text-center contacttopbg" id="contacttopbg " style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.906)), url(${props.icon})` }}>
                <div className="col-12 mt-5">
                    <h1>{props.name}</h1>
                    <p className="navlinkdesbtn2" style={{ backgroundColor: '#00A0E3' }}>Want to get in touch? We’d love to hear from you. Here is how you can contact us-</p>
                </div>
            </div>
            <div className="container contact">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h2 className="formtophead" style={{ fontWeight: "900", textAlign: "center" }}>Feel free to contact us
                            </h2>
                            <p style={{ textAlign: "center", marginBottom: "0px" }}>{props.p}</p>
                        </div>
                        <div className="col-md-4 col-12">
                            <h2 className="getintouchh2">Get in touch</h2>
                            <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="row">
                                    <div className="col-12">
                                        <img src={require('../../images/bg.png').default} className="contacticonlogo" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <i style={{ fontSize: "18px" }} className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div className="col-10 footercontact">
                                        <p>
                                            2, Radha Krishna Kunj,
                                            <br />
                                            Near Gagan Enclave, Ghaziabad (U.P.)
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <i style={{ fontSize: "18px" }} className="fas fa-phone-alt"></i>
                                    </div>
                                    <div className="col-10 footercontact">
                                        <p>8279616105,9873608287</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <i style={{ fontSize: "18px" }} className="fas fa-envelope"></i>
                                    </div>
                                    <div className="col-10   footercontact">
                                        <p>connect@asianplayshool.com </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8 col-12 align-self-center">
                            <div>
                                <form id="contact-form" onSubmit={handleSubmit} method="post">
                                    <div className="form-row">
                                        <div className="col">
                                            <label for="exampleFormControlInput1">First Name</label>
                                            <input id="FirstName" maxlength="15" name="firstname" className="contact_form_style form-control" type="text" value={firstname} onChange={(e) => setFirstName(e.target.value)} placeholder="First name" required />
                                            {/* <input required="" className="contact_form_style form-control" name="phone"  value={formValue.phone} onChange={handleChange}  type="text" id="Number" maxlength="10" placeholder="Please Enter Your Number" /> */}
                                            {/* <input placeholder='First Name' onChange={(e) => setFirstName(e.target.value)}/> */}
                                        </div>
                                        <div className="col">
                                            <label for="exampleFormControlInput1">Last Name</label>
                                            <input id="LastName" maxlength="15" name="lastName" className="contact_form_style form-control" value={lastname} onChange={(e) => setLastName(e.target.value)} type="text" placeholder="Last name" required />
                                        </div>
                                    </div>
                                    <div className="form-row phone_div " >
                                        <div className="col">
                                            <label for="exampleFormControlInput1">Phone Number</label>
                                            <input  className="contact_form_style form-control" name="phone" value={phone} onChange={(e) => setphone(e.target.value)} type="text" id="Number" maxlength="10" placeholder="Please Enter Your Number" required />
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <label for="exampleFormControlInput1">Email address</label>
                                        <input className="contact_form_style form-control" type="email" name="email" id="Email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="name@example.com" required />
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleFormControlTextarea1">Message</label>
                                        <textarea  className="contact_form_style form-control" name="message" id="Message" rows="3" placeholder="Feel Free To Talk To Us" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                                    </div>
                                    {/* {showErrors ? errorMessages.map((item, index) => {
                                        return <ul key={index}>{item}</ul>;
                                    }) : null} */}
                                    <button id="submit" className="form-control borderradiuscurve" >
                                        Send Message
                                    </button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div >
    )
}

export default Contact
