import Navbar from './components/navbar/Navbar';
import Welcome from './components/welcome/Welcome';
import Home from './components/home/Home';
import './App.css';
import React, { Fragment } from "react";
import { BrowserRouter as Router,Switch, Route, Link } from "react-router-dom";
// import { Routes, Route } from "react-router-dom";
import Footer from './components/footer/Footer';
import Contact from './components/contact/Contact';
import Franchise from './components/franchise/Franchise';
import About from './components/about/About';
import Gallary from './components/Gallary/Gallary';
import Thanks from './components/thanks/Thanks';
// import { HashRouter } from 'react-router-dom';
import ScrollToTop from './components/about/scrollontop/ScrollToTop';
import icon1 from "../src/images/contactusnew.jpg";
import icon2 from "../src/images/franchise3.png";
import ReactGA from 'react-ga';
import RouteChangeTracker from ".././src/components/RouteChangeTracker";
const TRACKING_ID = "UA-213150269-1"; 
ReactGA.initialize(TRACKING_ID);
function App() {
  return (
    <>
      <Router>
        <ScrollToTop/>
        <RouteChangeTracker/>
        <RouteChangeTracker/>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Welcome}/>
          <Route exact path="/franchise" component={Franchise}/>
            
          <Route exact path="/gallery" component={Gallary}/>
             
          <Route exact path="/about" component={About}/>
          <Route exact path="/thank" component={Thanks}/>
            
          <Route exact path="/contact">
            <Contact name="Contact Us" icon={icon1} p="Asian Play School - A foundation for your child's future." title="AsianPlaySchool | Contact"/>

          </Route>
          <Route exact path="/career">
            <Contact name="Career" icon={icon2} p="Asian Play School will help you to grow in your career." title="AsianPlaySchool | Career" />

          </Route>
          {/* <Route exact path="/school">
          <Contact name="Career" icon={icon2} p="Asian Play School will help you to grow in your career." title="AsianPlaySchool | Career" />
          </Route> */}
          
            
        </Switch>
        <Footer />
        </Router>
    </>
  );
}

export default App;
