import React from 'react'

const New = ({ data, morenumber, lessnumber }) => {
    // console.log(data.filter(o=>o.id >= morenumber && o.id <= lessnumber ))
    return (
        <div className="row text-center mt-2 justify-content-around learnboxx">

            {/* {
                
                data.filter(o=>o.id >= morenumber && o.id <= lessnumber ).map((item) => {
                return (<> */}
            <div className="col-md-3 col-12 borderradiuscurve" style={{ backgroundColor: `#009846`, padding: "40px 20px" }}>
                {/* <div className="col-md-3 col-12 borderradiuscurve" style={{ backgroundColor: `${item.color}`, padding: "40px 20px" }}> */}
                {/* <h2 style={{ color: "white", fontWeight: "800" }}>{item.title}</h2> */}
                
                <ul style={{ color: "white", fontSize: "17px" }} className="text-left">
                <li style={{ color: "white" }}>To observe their surroundings and become creative.</li>
                    <li style={{ color: "white" }}>Enjoy learning as we use STEAM for making learning fun for children.</li>
                    {/* <li style={{ color: "white" }}>To observe their surroundings and become creative.</li> */}
                    <li style={{ color: "white" }}>Your child's pronunciation improves as we use phonic sounds to help them understand the rhythm of words.</li>
                </ul>
            </div>
            {/* </>)
                })
            } */}

            <div className="col-md-3 col-12 borderradiuscurve" style={{ backgroundColor: `#00A0E3`, padding: "40px 20px" }}>
                {/* <div className="col-md-3 col-12 borderradiuscurve" style={{ backgroundColor: `${item.color}`, padding: "40px 20px" }}> */}
                {/* <h2 style={{ color: "white", fontWeight: "800" }}>{item.title}</h2> */}
               
                <ul style={{ color: "white", fontSize: "17px" }} className="text-left">
                    <li style={{ color: "white" }}>Learn numbers easily as we make numbers a fun activity for kids.</li>
                    <li style={{ color: "white" }}>Children get along while playing, that's why we use different fun activities to engage them.</li>
                    <li style={{ color: "white" }}>To recognize vowels and consonants and form letters thereafter.
                    </li>
                </ul>
            </div>

            <div className="col-md-3 col-12 borderradiuscurve" style={{ backgroundColor: `#E5097F`, padding: "40px 20px" }}>
                {/* <div className="col-md-3 col-12 borderradiuscurve" style={{ backgroundColor: `${item.color}`, padding: "40px 20px" }}> */}
                {/* <h2 style={{ color: "white", fontWeight: "800" }}>{item.title}</h2> */}
               
                <ul style={{ color: "white", fontSize: "17px" }} className="text-left">
                    <li style={{ color: "white" }}>Start recognizing daily used objects by their name and uses.</li>
                    <li style={{ color: "white" }}>Learn the use of the abacus which helps them solve mathematical problems easily.</li>
                    <li style={{ color: "white" }}>An effective way to communicate and convey their views.</li>
                </ul>
            </div>




        </div>
    )
}

export default New
