import React from 'react'
import "./home.css";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// import "../../../src/components/site.css"

const Home = () => {
    return (
        <div className="home">
            <Helmet>
                <title>AsianPlaySchool | Home</title>
                <meta name="description" content="Asian Play School is a premium preschool that runs under the guidance of education professionals with a combined experience of over 30 years. Our focus is on fostering learning experiences for students." />
                <meta name="keywords" content="Asian Play  School, Pre School, Home"></meta>
            </Helmet>
            <div className="col-12 text-center abutusdiv">
                <h1 style={{ fontSize: "55px", fontWeight: "900", color: "#009846" }}>Why choose <span className="funfont" style={{ fontSize: "55px" }}>Asian Play School</span> ?</h1>
            </div>
            <div className="row">
                <div className="col-md-4 col-12 mt-4 img_div1" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="borderradiuscurve text-center" style={{ backgroundColor: "#F47736", padding: "10px 20px" }}>
                            <h2 style={{ color: "white", fontWeight: "800" }}>Set Up</h2>
                            <ul style={{ color: "white" }} class="text-left">
                                <li style={{ color: "white" }}>Arranging site visits &amp; guidance on proposed locations</li>
                                <li style={{ color: "white" }}>Issuing a customized interior and exterior design</li>
                                <li style={{ color: "white" }}>Drafting and finalizing legal agreements</li>
                            </ul>
                        </div>
                    </div>
                </div> <div className="col-md-4 col-12 mt-4 img_div2" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="borderradiuscurve text-center" style={{ backgroundColor: "#F47736", padding: "10px 20px" }}>
                            <h2 style={{ color: "white", fontWeight: "800" }}>Marketing</h2>
                            <ul style={{ color: "white" }} class="text-left">
                                <li style={{ color: "white" }}>Designing marketing material such as invites &amp; hoardings</li>
                                <li style={{ color: "white" }}>Social Media Advertisement with regular posts and videos</li>
                                <li style={{ color: "white" }}>Monthly Admission leads will be provided for every location</li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-12 mt-4 img_div3" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="borderradiuscurve text-center" style={{ backgroundColor: "#F47736", padding: "10px 20px" }}>
                            <h2 style={{ color: "white", fontWeight: "800" }}>Day to Day</h2>
                            <ul style={{ color: "white" }} class="text-left">
                                <li style={{ color: "white" }}>Assistance in requisitioning of school supplies and employees</li>
                                <li style={{ color: "white" }}>Creating a support application for easy management</li>
                                <li style={{ color: "white" }}>Curriculum and study-material assistance for all classes</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ position: "relative" }}>
                <img style={{ position: "absolute", width: "28vw", top: "70vh" }} src={require("../../images/bg3.png").default} />
                <img style={{ position: "absolute", width: "28vw", top: "10vh", right: "0" }} src={require("../../images/bg4.png").default} />

                <div className="col-12 text-center abutusdiv">
                    <h1 style={{ fontSize: "50px", fontWeight: "900", color: "#009846" }}>Some of our best <span className="funfont" style={{ fontSize: "50px" }}>events</span>.</h1>
                </div>
                <div className="col-12 text-center">
                    <iframe className="borderradiuscurve" title="VIRTUAL ZOO DAY LIVE 2: Baby meerkats have breakfast!" width="500" height="281" src="https://www.youtube.com/embed/SCLdnudCXMc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="">
                    </iframe>
                </div>
                <div className="col-12 text-center">
                    <iframe className="borderradiuscurve" title="VIRTUAL ZOO DAY LIVE 2: Baby meerkats have breakfast!" width="500" height="281" src="https://www.youtube.com/embed/vGrP_HfZUqE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="">
                    </iframe>
                </div>
                <div className="row" style={{ padding: "100px 50px" }}>
                    <div className="col-12 col-md-3 text-center" style={{ paddingTop: "60px" }}>
                        <p className="catchup"><span className="borderradiuscurve" style={{ backgroundColor: "#F36C38", color: "white", padding: "5px", fontSize: "30px" }}>CATCH UP</span>
                        </p>
                        <br />
                        <p className="fun"><span className="borderradiuscurve funfont" style={{ backgroundColor: "#00A0E3", color: "white", padding: "5px", fontSize: "47px" }}>&amp; Join the
                            Fun</span></p>
                    </div>
                    <div className="col-12 col-md-9 text-center borderradiuscurve p-4" style={{ backgroundColor: "#009846", color: "white" }}>
                        <p style={{ fontSize: "25px", color: "white" }}>Missed any of our amazing events? You can find all of our <span style={{ color: "white" }}>ASAIN PLAY SCHOOL EVENTS </span>on our Facebook page. Follow us for
                            regular learning and updates..</p>
                        <Link className="btn borderradiuscurve" style={{ backgroundColor: "white", color: "#009846", fontWeight: "900" }}>Follow Us on Facebook</Link>
                    </div>
                </div>
            </div>


            {/* <!-- Start your own play school --> */}
            <div className="row text-center" style={{ padding: '50px 0px' }} >
                <div className="col-12 text-center">
                    <h1 className="funfont homeopenplayschoolh1" style={{ color: "#F36C38" }}>
                        <img src={require("../../images/headingsupport.png").default} style={{ width: '3vw', transform: 'rotate(180deg)', }} />
                        Open Your Own 
                        <span className="funfont" style={{ fontSize: '50px' }}>&nbsp;Play School</span><img
                            src={require("../../images/headingsupport.png").default} style={{ width: '3vw',transform: 'rotate(0deg)' }} />
                    </h1>
                </div>
                <div className="col-12 text-center firstfranchisediv">
                    <div className="row ">
                        <div className="col-12 col-md-6 Qualitybox  justify-content-center" >
                            <div className="borderradiuscurve" style={{ backgroundColor: '#e5097eb4', padding: '30px' }} >
                                <h2 style={{ color: "white", fontWeight: "700" }}>Make an Impact to help people receive quality
                                    education</h2>
                                <h5 style={{ color: 'white' }}>The preschool franchise business is suitable for individuals who
                                    want
                                    to start a business that is long-term in nature, investment friendly and has the
                                    potential
                                    to give stable returns.Asian Play School is the perfect partner who will help you
                                </h5>
                                <div className="col-4 d-flex">
                                    <Link className="nav-link  justify-content-center " style={{ borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%", backgroundColor: "#ffff", color: "#E64A9D" }} to="/career">Know more</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-center secondfranchisediv">
                    <div className="row">
                        <div className="col-12 col-md-6">

                        </div>
                        <div className="col-12 col-md-6 Qualitybox">
                            <div className="borderradiuscurve" style={{ backgroundColor: '#009fe3b6', padding: '30px' }} >
                                <h2 style={{ color: "white", fontWeight: "700" }}>Low Risk, High Return</h2>
                                <h5 style={{ color: 'white' }}>Opening a franchise would allow you to monetize your vacant space. With an initial investment of 7-15 Lakhs*, you would be able to start a sustainable business. Furthermore, the probability of a Play School failing is low and hence, it becomes a source of Regular income.
                                </h5>
                                <div className="col-4 ">
                                    <Link className="nav-link " style={{ borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%", backgroundColor: "#ffff", color: "#3297BB" }} to="/career">Know more</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-center thirdfranchisediv">
                    <div className="row">
                        <div className="col-12 col-md-6 Qualitybox">
                            <div className="borderradiuscurve" style={{ backgroundColor: '#009847b6', padding: '30px' }} >
                                <h2 style={{ color: "white", fontWeight: "700" }}>Spend Time with small children</h2>
                                <h5 style={{ color: 'white' }}>For homemakers who wish to spend time with children, playschools can be a great way to do so. It allows them to rejoice and helps the children get a motherly touch. It means that the child and the homemaker are able to benefit from a mutual relationship.
                                </h5>
                                <div className="col-4 ">
                                    <Link className="nav-link " style={{ borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%", backgroundColor: "#ffff", color: "#36904F"}} to="/career">Know more</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Home
