import React from 'react';
import "./welcome.css";
import Child from "../child/Child";
import Home from '../home/Home';
import { Carousel } from "react-responsive-carousel";
// import Carousal from '../carousel/Carousal';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Welcome = () => {
    return (
        <div className="welcome">
            <Carousel className="HideInPhone" autoPlay>
                <div>
                    <img alt="" src={require("../../images/1.png").default} />
                </div>
                <div>
                    <img alt="" src={require("../../images/2.png").default} />
                </div>
                <div>
                    <img alt="" src={require("../../images/3.png").default} />
                </div>
            </Carousel>
            <Carousel className="ShowInPhone HideInDesktop" autoPlay>
                <div>
                    <img alt="" src={require("../../images/mobilebg1.png").default} />
                </div>
                <div>
                    <img alt="" src={require("../../images/mobilebg2.png").default} />
                </div>
                <div>
                    <img alt="" src={require("../../images/mobilebg3.png").default} />
                </div>
            </Carousel>
            <div class="row" style={{ position: "relative" }}>
                <img class="topimg3" src={require("../../images/bg.png").default} alt="" />
                <img class="topimage2" src={require("../../images/bg2.png").default} alt=""></img>

                <div className="col-12 text-center abutusdiv">
                    <h1 style={{ fontSize: "40px", fontWeight: 900, color: "#009846" }}>Welcome to <span className="funfont" style={{ fontSize: "40px" }}>Asian Play School</span></h1>
                    <p style={{ fontSize: "25px" }}>Asian Play School is a premium preschool that runs under the guidance of
                        education professionals with
                        a combined experience of over <span className="funfont" style={{ fontSize: "30px" }}>30 years</span>. Our
                        focus is on fostering learning experiences for students.
                        We believe that each child is a unique individual who needs a secure, caring, and stimulating
                        atmosphere in which to grow and mature</p>
                </div>
            </div>
            <Child />
            <Home />

        </div>
    )
}

export default Welcome
