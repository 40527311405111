import React from 'react';
import Child from '../child/Child';
import "./about.css";
import { Helmet } from 'react-helmet';

const About = () => {
    return (
        <div className="about">
            <Helmet>
                <title>AsianPlaySchool | About</title>
                <meta name="description" content="Asian Play School is a premium preschool that runs under the guidance of education professionals with a combined experience of over 30 years. Our focus is on fostering learning experiences for students." />
                <meta name="keywords" content="Asian Play  School, Pre School, About"></meta>
            </Helmet>
            <div className="row align-items-center text-center aboutustopbg" id="contacttopbg">
                <div className="col-12 mt-5">
                    <h1>About Us</h1>
                    <p className="navlinkdesbtn2">Asian Playschool is a pioneer in early childhood care and education. We are India’s most innovative preschool chain. We are committed to spreading child development across the nation with creative engagement and mind development. We focus on bringing forth the unique potential of every child.</p>
                </div>
            </div>
            <Child />
        </div>
    )
}

export default About
