import React,{useEffect} from 'react';
import "./franchise.css";
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import ReactGA from 'react-ga';
import {Link} from "react-router-dom"

ReactGA.initialize('AW-10808994388');
const Franchise = () => {
    ReactGA.event({
        category: 'conversion',
        send_to: 'AW-10808994388/Wr5JCITzyoMDENTUkKIo',
        // label: 'Game Widget'
      });

    // window.gtag("event", "login", {
    //     event_category: "access",
    //     event_label: "login"
    // });

    

    useEffect(() => {

       
        // gtag('event', 'conversion', {'send_to': 'AW-10808994388/Wr5JCITzyoMDENTUkKIo'});

    }, [])







    // < !--Global site tag(gtag.js) - Google Ads: 10808994388 -- >
    {/* <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10808994388"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-10808994388');
</script> */}


    const [firstname, setFirstName] = useState();
    const [lastname, setLastName] = useState();
    const [phone, setphone] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const history = useHistory();



    const submitData = async (e) => {
        e.preventDefault();
        axios.get(`http://api.digisidekick.com/api/Mail?Email=rajatgupta02607@gmail.com&Firstname=${firstname}&Lastname=${lastname}&Number=${phone}&Usermial=${email}&UserMessage=${message}&other=null`,)
            .then((res) => {
                console.log(res.data)
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");

            }).catch((error) => {
                console.log(error)
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");
            });

        setFirstName("");
        setLastName("");
        setphone('');
        setEmail("");
        setMessage("");
        history.push("/thank")

    }


    return (
        <div className="franchise">
            <Helmet>
                <title>AsianPlaySchool | Franchise</title>
                <meta name="description" content="Asian Play School is a premium preschool that runs under the guidance of education professionals with a combined experience of over 30 years. Our focus is on fostering learning experiences for students." />
                <meta name="keywords" content="Asian Play  School, Pre School, Franchise"></meta>
            </Helmet>
            <div className="row align-items-center text-center franchisetopbg" id="franchisetopbg">
                <div className="HideInPhone col-md-5 col-12 order-md-1 order-2" style={{ padding: "100px 30px 0px 80px" }}>
                    <form id="contact-form" style={{ backgroundColor: "white", padding: "20px", color: "#009846", borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%" }}  >
                        <div className="form-row text-center">
                            <div className="col-12">
                                <h4 >Get free consultancy
                                    from Experts</h4>
                            </div>
                            <div className="col">
                                <input  id="FirstName" maxlength="15" type="text" onkeypress="return /[a-z]/i.test(event.key)" className="form-control franchise_form_style" placeholder="First Name" value={firstname} onChange={(e) => setFirstName(e.target.value)} required />
                            </div>
                            <div className="col">
                                {/* <!-- <label for="exampleFormControlInput1">Last Name</label> --> */}
                                <input  id="LastName" maxlength="15" onkeypress="return /[a-z]/i.test(event.key)" type="text" className="form-control franchise_form_style" placeholder="Last Name" value={lastname} onChange={(e) => setLastName(e.target.value)} required />
                            </div>
                        </div>
                        <div className="form-row  text-left" style={{ marginTop: "5px", marginBottom: "5px", borderRadius: "2% 66% 11% 41% / 34% 21% 52% 19%" }}>
                            <div className="col">
                                {/* <!-- <label for="exampleFormControlInput1">Phone Number</label> --> */}
                                <input  onkeypress="return /[0-9]/i.test(event.key)" type="number" className="form-control franchise_form_style" id="Number" maxlength="10" placeholder="Contact Number" value={phone} onChange={(e) => setphone(e.target.value)} required />
                            </div>

                        </div>
                        <div className="form-group text-left">
                            {/* <!-- <label for="exampleFormControlInput1">Email address</label> --> */}
                            <input  type="email" className="form-control franchise_form_style" id="Email" placeholder="Email ID" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                        <div className="form-group text-left">
                            {/* <!-- <label for="exampleFormControlTextarea1">Message</label> --> */}
                            <textarea  style={{ border: "1px solid black", borderRadius: "5% 66% 11% 41% / 34% 21% 52% 19%" }} className="form-control" id="Message" rows="3" placeholder="Feel Free To Talk To Us" value={message} onChange={(e) => setMessage(e.target.value)} required ></textarea>
                        </div>
                        <button id="submit" onClick={submitData} style={{ borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%", backgroundColor: "#F36C38", color: "white", textAlign: "center", textDecoration: "none", cursor: "pointer" }} className="form-control borderradiuscurve">
                            Send Message
                        </button>
                    </form>
                </div>
                <div className="col-md-7 col-12 order-md-2 order-1 headertopcontentfranchise">
                    <div style={{ backgroundColor: "rgba(255, 255, 255)", color: "#F36C38", padding: "20px", borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%" }}>
                        <h4 style={{ fontWeight: "700", color: "#58595B", }}>LEVERAGE OUR EXPERTISE IN EARLY EDUCATION. BRING THE WORLD'S TOP PRESCHOOL EDUCATION TO YOUR AREA!</h4>
                        <h2 style={{ fontWeight: "800" }}>OWN AN ASIAN PLAY SCHOOL</h2>
                        <div className="row">
                            <div className="col-6">
                                <Link className="nav-link" style={{ borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%", backgroundColor: "#009846", color: "white" }} to="/contact">All you need to get started</Link>
                            </div>
                            <div className="col-6">
                                <Link className="nav-link" style={{ borderRadius: "3% 45% 13% 9%/35% 9% 7% 10%", backgroundColor: "transparent", color: "#009846", border: "2px solid #009846" }} to="/contact">Benefits of owning Asian Play school</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row" style={{ position: "relative" }}>

                <div className="col-12 text-center abutusdiv">
                    <h1 className="aboutusfranchise"><span className="funfont" style={{ fontSize: "40px" }}>Asian Play School</span><br />Partner with India's most innovative
                        Pre-School chain</h1>
                    <p style={{ fontSize: "25px", color: "#58595B" }}>This is suitable for individuals who want to start a business that is long-term in nature and has the potential to give stable returns. When you start a pre-school, you will require a partner who has an experience of how to run a successful pre-school franchise.</p>
                    <span style={{ color: "#F36C38", fontSize: "25px" }}>Asian Play School is that perfect partner for your new journey.</span>
                </div>
            </div>
            {/* <Home/> */}
            <div className="col-12 text-center abutusdiv">
                <h1 style={{ fontSize: "55px", fontWeight: "900", color: "#009846" }}>Why choose <span className="funfont" style={{ fontSize: "55px" }}>Asian Play School franchise</span> ?</h1>
            </div>
            <div className="row">
                <div className="col-md-4 col-12 mt-4 img_div1" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="borderradiuscurve text-center" style={{ backgroundColor: "#F47736", padding: "10px 20px" }}>
                            <h2 style={{ color: "white", fontWeight: "800" }}>Set Up</h2>
                            <ul style={{ color: "white" }} class="text-left">
                                <li style={{ color: "white" }}>Arranging site visits &amp; guidance on proposed locations</li>
                                <li style={{ color: "white" }}>Issuing a customized interior and exterior design</li>
                                <li style={{ color: "white" }}>Drafting and finalizing legal agreements</li>
                            </ul>
                        </div>
                    </div>
                </div> <div className="col-md-4 col-12 mt-4 img_div2" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="borderradiuscurve text-center" style={{ backgroundColor: "#F47736", padding: "10px 20px" }}>
                            <h2 style={{ color: "white", fontWeight: "800" }}>Marketing</h2>
                            <ul style={{ color: "white" }} class="text-left">
                                <li style={{ color: "white" }}>Designing marketing material such as invites &amp; hoardings</li>
                                <li style={{ color: "white" }}>Social Media Advertisement with regular posts and videos</li>
                                <li style={{ color: "white" }}>Monthly Admission leads will be provided for every location</li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-12 mt-4 img_div3" >
                    <div style={{ padding: "25px", position: 'absolute', bottom: "0" }}>
                        <div className="borderradiuscurve text-center" style={{ backgroundColor: "#F47736", padding: "10px 20px" }}>
                            <h2 style={{ color: "white", fontWeight: "800" }}>Day to Day</h2>
                            <ul style={{ color: "white" }} class="text-left">
                                <li style={{ color: "white" }}>Assistance in requisitioning of school supplies and employees</li>
                                <li style={{ color: "white" }}>Creating a support application for easy management</li>
                                <li style={{ color: "white" }}>Curriculum and study-material assistance for all classes</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center learnwilldiv" style={{ paddingTop: "70px" }}>
                <h1 style={{ fontSize: "40px", fontWeight: "900", color: "#009846" }}>What all you need to get started?</h1>
                <p style={{ fontSize: "21px", color: "#F36C38" }}>To start a play school firstly you need commitment</p>
            </div>
            <div className="row text-center justify-content-around">
                <div class="col-md-3 col-12 borderradiuscurve " style={{ backgroundColor: "#00A0E3", padding: " 40px 20px " }}>
                    <h2 style={{ color: "white", fontWeight: 800 }}>Long Term Vision</h2>
                    <p style={{ color: "white", fontSize: "17px" }} class="text-center">Partners must join the franchise network
                        by recognising that this is a business with sustained returns, provided one has a long-term vision
                        towards the venture.</p>
                </div>
                <div class="col-md-3 col-12 borderradiuscurve " style={{ backgroundColor: "#009846", padding: " 40px 20px " }}>
                    <h2 style={{ color: "white", fontWeight: 800 }}>Investment</h2>
                    <p style={{ color: "white", fontSize: "17px" }} class="text-center">The initial investment amount varies
                        from 7 Lakh* to 15 Lakh*. It depends
                        upon the size of the pre-school and the city in which you are
                        present in.</p>
                </div>
                <div class="col-md-3 col-12 borderradiuscurve" style={{ backgroundColor: "#E5097F", padding: " 40px 20px " }}>
                    <h2 style={{ color: "white", fontWeight: 800 }}>Management</h2>
                    <p style={{ color: "white", fontSize: "17px" }} class="text-center">We will help you in day-to-day management by helping out in marketing endeavours and providing learning resources for the children. We will also provide you with updates in the field of Early Education and help you adapt to the same</p>
                </div>
            </div>
            <div className="row" style={{ padding: "100px 50px" }}>
                <div className="col-12 col-md-3 text-center" style={{ paddingTop: "60px" }}>
                    <p className="catchup"><span className="borderradiuscurve" style={{ backgroundColor: "#F36C38", color: "white", padding: "5px", fontSize: "30px" }}>CATCH UP</span>
                    </p>
                    <br />
                    <p className="fun"><span className="borderradiuscurve funfont" style={{ backgroundColor: "#00A0E3", color: "white", padding: "5px", fontSize: "47px" }}>&amp; Join the
                        Fun</span></p>
                </div>
                <div className="col-12 col-md-9 text-center borderradiuscurve p-4" style={{ backgroundColor: "#009846", color: "white" }}>
                    <p style={{ fontSize: "25px", color: "white" }}>Missed any of our amazing events? You can find all of our <span style={{ color: "white" }}>ASAIN PLAY SCHOOL EVENTS </span>on our Facebook page. Follow us for
                        regular learning and updates..</p>
                    <a className="btn borderradiuscurve" style={{ backgroundColor: "white", color: "#009846", fontWeight: "900" }}>Follow Us on Facebook</a>
                </div>
            </div>
            <div className="row text-center" style={{ padding: '50px 0px' }} >
                <div className="col-12 text-center">
                    <h1 className="funfont homeopenplayschoolh1">
                        <img src={require("../../images/headingsupport.png").default} style={{ width: '3vw', transform: 'rotate(180deg)' }} />
                        Open Your Own
                        <span className="funfont" style={{ fontSize: '50px' }}>Play School</span><img
                            src={require("../../images/headingsupport.png").default} style={{ width: '3vw', transform: 'rotate(0deg)' }} />
                    </h1>
                </div>
                <div className="col-12 text-center firstfranchisediv">
                    <div className="row">
                        <div className="col-12 col-md-6 Qualitybox" >
                            <div className="borderradiuscurve" style={{ backgroundColor: '#e5097eb4', padding: '30px' }} >
                                <h2 style={{ color: "white", fontWeight: "700" }}>Make an Impact to help people receive quality
                                    education</h2>
                                <h5 style={{ color: 'white' }}>The preschool franchise business is suitable for individuals who
                                    want
                                    to start a business that is long-term in nature, investment friendly and has the
                                    potential
                                    to give stable returns.Asian Play School is that perfect partner who will help you
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 text-center secondfranchisediv">
                    <div className="row">
                        <div className="col-12 col-md-6">
                        </div>
                        <div className="col-12 col-md-6 Qualitybox">
                            <div className="borderradiuscurve" style={{ backgroundColor: '#009fe3b6', padding: '30px' }} >
                                <h2 style={{ color: "white", fontWeight: "700" }}>Low Risk, High Return</h2>
                                <h5 style={{ color: 'white' }}>opening a franchise would allow you to monetize your vacant space. With an initial investment of 7-15 Lakhs*, you would be able to start a sustainable business. Furthermore, the probability of a Play School failing is low and hence, it becomes a source of Regular income.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-center thirdfranchisediv">
                    <div className="row">
                        <div className="col-12 col-md-6 Qualitybox">
                            <div className="borderradiuscurve" style={{ backgroundColor: '#009847b6', padding: '30px' }} >
                                <h2 style={{ color: "white", fontWeight: "700" }}>Spend Time with small children</h2>
                                <h5 style={{ color: 'white' }}>Spend Time with small children
                                    For homemakers who wish to spend time with children, playschools can be a great way to do so. It allows them to rejoice and helps the children get a motherly touch. It means that the child and the homemaker are able to benefit from a mutual relationship.

                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Franchise
